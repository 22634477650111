import React from 'react';
import { useNavigate, useLocation } from 'react-router';
import { WizardHeader, DisplayStoreList} from '../ngoNcCreateEvents/NgoNcSpaceReqStoreListComp'
import SpaceDonationService, { Service } from 'src/spaceDonationService';
import { useQuery } from 'react-query';

const service: Service = SpaceDonationService;

export interface NgoNcSpaceReqStoreListCompProp {
    history?: any[]
}
interface NcIDProps {
    pathname: string;
    state: {
        ncid: any;
        orgid: any;
    }
}
const NgoNcSpaceReqStoreListEditComp: React.FC<NgoNcSpaceReqStoreListCompProp> = ( ) => {
    const history = useNavigate();
    const {state} = useLocation() as NcIDProps;
    const ncid = state?.ncid;
    const orgid = state?.orgid;
    const {
        data: selectedStoreList,
        isLoading,
        refetch } =
        useQuery(['getSelectedStoreByOrgForNC', { ncid, orgid }],
            service.getSelectedStoreByOrgForNC, { refetchOnWindowFocus: false });

    const handlePreviousRedirect = () =>{
        history(`/GlobalDonations/gdms/space-donation/edit/store-select`,
           { state: {ncid:state?.ncid, orgid:state?.orgid}}
        )
    }
    const headerSection = {
        title: 'Add Store',
        progressTrackerItem: ['Select Store', 'Date and Time', 'Summary'],
        activeIndex: 1,
        handlePreviousRedirect
    }

    return (
        <div>
            <WizardHeader {...headerSection} />
            <DisplayStoreList
                flow='edit'
                history={history}
                prevPath={`/GlobalDonations/gdms/space-donation/edit/store-select`}
                continuePath={`/GlobalDonations/gdms/space-donation/edit/store-details`}
                selectedStoreList={selectedStoreList}
                isLoading={isLoading}
                refetch={refetch}
            />
        </div>
    )
}

export default NgoNcSpaceReqStoreListEditComp;
