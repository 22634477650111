import React from 'react';
import withRouter from 'src/polyfils/customRouter';

class Faq extends React.Component {
    public componentDidMount(){
        window.open(`https://walmart.org/local-organization-faqs`);
        // @ts-ignore
        this.props.history.goBack();
    }
    public render(){
        return <div>downloading....</div>
    }
}

// @ts-ignore
export default withRouter(Faq);