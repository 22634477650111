import React, { useState, useCallback, useEffect } from 'react';
import _debounce from 'lodash/debounce';
import TablePagination from '@material-ui/core/TablePagination';
import { ChevronLeft } from '@livingdesign/icons';
import {
    TextField,
    ProgressTracker,
    ProgressTrackerItem,
    Grid,
    GridColumn,
    Card,
    Divider,
    Select
} from '@walmart-web/livingdesign-components';
import { useNavigate, useLocation } from 'react-router';
import LDCheckbox from '../../commonComponents/uiComponents/LDCheckBox';
import SpaceDonationService, { Service } from 'src/spaceDonationService';
import { useMutation } from 'react-query';
import { MapComponent } from '../createSpaceDonation/spaceDonationStoreSelector';
import LoaderComponent from '../../commonComponents/appComponents/loader/LoaderComponent';
import LDButton from '../../commonComponents/uiComponents/LDButton';

import './ngoNcSelectStoreComp.css';
import LDModal from '../../commonComponents/uiComponents/LDModal';


const service: Service = SpaceDonationService;

interface NcIDProps {
    pathname: string;
    state: {
        ncid: any;
        spaceOrganizationid?: any;
        orgid?: any;
    }
}

const NgoNcSelectStoreComp = () => {
    const history = useNavigate();
    const [continueEnabler, setContinueEnabler] = useState(true);
    const [selectedStore, getSelectedStore] = useState([]);

    const { state } = useLocation() as NcIDProps;

    const ncid = state?.ncid;
    const spaceOrganizationid = state?.spaceOrganizationid || state?.orgid
    const headerSection = {
        title: 'Request Space',
        progressTrackerItem: ['Select Store', 'Date and Time', 'Summary'],
        activeIndex: 0,
        ncId: ncid,
        orgid: spaceOrganizationid,
    }

    if (ncid === undefined || spaceOrganizationid === undefined) {
        history(`/GlobalDonations/gdms/space-donation/create/`)
    }
    return (
        <div>
            <WizardHeader {...headerSection} />
            <StoreSelector
                getSelectedStore={getSelectedStore}
                setContinueEnabler={setContinueEnabler}
                ncId={ncid}
                spaceOrganizationId={spaceOrganizationid}
            />
            <WizardFooter
                selectedStore={selectedStore}
                isContinueDisabled={continueEnabler}
                ncId={ncid}
                spaceOrganizationId={spaceOrganizationid}
            />
        </div>
    )
}

interface WizardHeaderProp {
    title?: string
    progressTrackerItem?: any[]
    activeIndex?: number
    prevPath?: any;
    ncId: any;
    orgid: any;
}


interface WizardFooterProp {
    isContinueDisabled?: boolean
    selectedStore: any[];
    ncId: any;
    spaceOrganizationId: any;
    prevPath?: any;
    continuePath?: any;
}

export const WizardHeader: React.FC<WizardHeaderProp> = ({ title, progressTrackerItem, activeIndex, prevPath, ncId, orgid }) => {
    const history = useNavigate()
    const handleClick = () => {
        history(prevPath || `/GlobalDonations/gdms/space-donation/create`,
            {
                state: { ncId, orgId: orgid }
            })
    }
    return (
        <div className='ngo-nc-ss-header-section'>
            <div className='ngo-nc-ss-header-title-section'>
                <ChevronLeft size='large' data-testid='chev-prev' onClick={handleClick} />
                <span className='ngo-nc-ss-header-title'>{title}</span>
            </div>
            <div className='ngo-nc-ss-progress-tracker'>
                <ProgressTracker activeIndex={activeIndex} variant='info'>
                    {progressTrackerItem?.map?.((item, index) => <ProgressTrackerItem key={index}>{item}</ProgressTrackerItem>)}
                </ProgressTracker>
            </div>
        </div>
    )
}

export const WizardFooter: React.FC<WizardFooterProp> = ({
    isContinueDisabled,
    selectedStore,
    ncId,
    spaceOrganizationId,
    prevPath,
    continuePath
}) => {
    
    const [isDraftError, setIsDraftError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const mutation = useMutation((dataStor: any) => service.saveDraftsNC(dataStor));
    const history = useNavigate()

    const handleContinueBtnClick = () => {
        const storeNmbrs = selectedStore.map((obj) => {
            return obj.storeNbr
        })
        const finalArray = selectedStore.map((obj) => {
            return {
                storeNbr: obj.storeNbr,
                storeEventDateRequestNPOList: []
            };
        });
        mutation.mutate({
            ncId,
            spaceOrganizationId,
            storeNbrList: storeNmbrs,
            isUpdate: false,
            storeEventDateRequestNPOList: finalArray
        }, {
            onError :(error: any) => {
                setIsDraftError(true);
                setErrorMessage(error.response.data.errorMessage);
            }
        }
        );
       
        }
    

    const handleClick = () => {
        history(prevPath || `/GlobalDonations/gdms/space-donation/create`,
            { state: { ncId, orgId: spaceOrganizationId } }
        )
    }
    useEffect(() => {
        if (mutation?.isSuccess) {
            history(continuePath || `/GlobalDonations/gdms/space-donation/create/store-details`,
                { state: { ncid: ncId, orgid: spaceOrganizationId } }
            )
        }
    }, [mutation])

    return (
        <>  
         <LDModal isOpen={isDraftError} size='small'
            onClose={() => setIsDraftError(false)}
            title={<div className='submit-error-message'>Error</div>}>
            {errorMessage}
        </LDModal>
            {mutation.isLoading && <LoaderComponent />}
            <div className='ngo-nc-ss-footer-section'>
                <div className='ngo-nc-ss-footer-previous'>
                    <LDButton onClick={handleClick} data-testid='prev-btn' variant='secondary'>Previous</LDButton>
                </div>
                <div className='ngo-nc-ss-footer-continue'>
                    <LDButton variant='primary'
                        data-testid='continue-btn'
                        onClick={handleContinueBtnClick}
                        disabled={isContinueDisabled}>
                        Continue
                    </LDButton>
                </div>
            </div>
        </>
    )
}

export const handleDebounceFn = (inputValue: any, columnFilter: any, props: any) => {
    const storeNbr = 'storeNbr';
    const postalCode = 'postalCode';
    const state = 'state';
    const city = 'city';
    if (inputValue.length) {
        switch (columnFilter) {
            case 'all':
                inputValue = inputValue.toLocaleLowerCase();
                props.setMethod(props.data.filter((obj: any) => {
                    return (
                        String(obj[storeNbr]).includes(inputValue) ||
                        obj[postalCode].toLocaleLowerCase().includes(inputValue) ||
                        obj[state].toLocaleLowerCase().includes(inputValue) ||
                        obj[city].toLocaleLowerCase().includes(inputValue)
                    );
                }))
                props.setPageCount(0);
                break;
            case 'zip':
                inputValue = inputValue.toLocaleLowerCase();
                props.setMethod(props.data.filter((obj: any) => {
                    return (
                        obj[postalCode].toLocaleLowerCase().includes(inputValue)
                    );
                }))
                props.setPageCount(0);
                break;
            case 'state':
                inputValue = inputValue.toLocaleLowerCase();
                props.setMethod(props.data.filter((obj: any) => {
                    return (
                        obj[state].toLocaleLowerCase().includes(inputValue)
                    );
                }))
                props.setPageCount(0);
                break;
            case 'city':
                inputValue = inputValue.toLocaleLowerCase();
                props.setMethod(props.data.filter((obj: any) => {
                    return (
                        obj[city].toLocaleLowerCase().includes(inputValue)
                    );
                }))
                props.setPageCount(0);
                break;
            case 'store_number':
                inputValue = inputValue.toLocaleLowerCase();
                props.setMethod(props.data.filter((obj: any) => {
                    return (
                        String(obj[storeNbr]).includes(inputValue)
                    );
                }))
                props.setPageCount(0);
                break;
        }
    } else {
        props.setMethod(props.data)
    }
}

const SearchComponent = (props: any) => {
    const [searchString, setSearchString] = useState(props.searchString);
    const [columnFilter, setColumnFilter] = useState('all')
    const debounceFn = useCallback(_debounce(handleDebounceFn, 500), []);
    const [placeHolder, setPlaceholder] = useState('Search By State/City/Zip')
    const handleChange = (event: any) => {
        setSearchString(event.target.value);
    };

    const handleSearchFilter = (value: any) => {
        setColumnFilter(value)
    }

    useEffect(() => {
        debounceFn(searchString, columnFilter, props);
    }, [columnFilter, searchString])

    useEffect(() => {
        switch (columnFilter) {
            case 'all':
                setPlaceholder('Search By State/City/Zip');
                break;
            case 'zip':
                setPlaceholder('Search by ZIP code')
                break;
            case 'state':
                setPlaceholder('Search by state');
                break;
            case 'city':
                setPlaceholder('Search by city');
                break;
            case 'store_number':
                setPlaceholder('Search by store number');
                break;
        }
    }, [columnFilter])
    return <>
        <div className='ngo-nc-ss-search-box'>
            <Select label={undefined} size="small"
                value={columnFilter}
                UNSAFE_className='ngo-nc-ss-column-filter'
                onChange={(event) => handleSearchFilter(event.target.value)}>
                <option value="all">All</option>
                <option value="store_number">Store #</option>
                <option value="city">City</option>
                <option value="state">State</option>
                <option value="zip">ZIP</option>
            </Select>
            <TextField
                UNSAFE_className='ngo-nc-text-field'
                id='store-search'
                label=''
                placeholder={placeHolder}
                value={searchString}
                onChange={handleChange}
                type='text'
                // @ts-ignore
                textFieldProps={{ 'data-testid': 'search-input' }}
            />
        </div></>
}

export const StoreSelector = (props: any) => {
    const [selectedStores, setSelectedStores] = useState([] as any);
    const [storesData, setStoresData] = useState([] as any);
    const [filteredData, setFilteredData] = useState([] as any);
    const [page, setPage] = useState(0);

    const mutation = useMutation((dataStor: any) => service.searchStoreNC(dataStor));

    useEffect(() => {
        mutation.mutate({
            key: '',
            ncId: props.ncId,
            orgId: props.spaceOrganizationId
        }, {
            onSuccess(data) {
                const selected = data.data.filter((row: any) => row.selected);
                setSelectedStores(selected);
            },
        });
    }, [])

    useEffect(() => {
        if (mutation.isSuccess) {
            setStoresData(mutation.data.data);
        }
    }, [mutation])

    useEffect(() => {
        setFilteredData(storesData)
    }, [storesData])

    useEffect(() => {
        props.setContinueEnabler(selectedStores.length === 0)
        props.getSelectedStore(selectedStores);
    }, [selectedStores]);

    const handleSelection = (event: any, ele: any) => {
        if (event.target.checked) {
            setSelectedStores([...selectedStores, ele])
        } else {
            const indX = selectedStores.findIndex((item: any) => item.storeNbr === ele.storeNbr);
            const removed = [...selectedStores]
            if (indX > -1) {
                removed.splice(indX, 1);
            }
            setSelectedStores(removed);
        }
    }

    const handleAllSelection = (event: any) => {
        setSelectedStores(event.target.checked ? filteredData : [])
    }

    return <div className='ngo-nc-store-select-parent-div'>
        {mutation.isLoading && <LoaderComponent />}
        <Grid hasGutter={true}>
            <GridColumn className='ngo-nc-ss-grid-column' sm={12} md={5}>
                <Card className='ngo-nc-ss-store-list'>
                    <div className='ngo-nc-ss-head-count'>
                        <div className='header'>Store List</div>
                        {selectedStores.length !== 0 && <div>Total {selectedStores.length} store selected</div>}
                    </div>
                    <div>
                        <SearchComponent data={storesData} setMethod={setFilteredData} setPageCount={setPage} />
                    </div>
                    {storesData.length !== 0 ? <DataTableCustom
                        data={filteredData}
                        selectedData={selectedStores}
                        handleSelection={handleSelection}
                        handleAllSelection={handleAllSelection}
                        pageCount={page}
                        setPageCount={setPage}
                    /> : <div className='ngo-nc-ss-head-count'>No Participating Stores available</div>}
                </Card>
            </GridColumn>
            <GridColumn className='ngo-nc-ss-grid-column' sm={12} md={7}>
                <Card className='ngo-nc-ss-store-list'>
                    <MapComponent
                        isMobile={false}
                        storeDetails={selectedStores.length !== 0 ? selectedStores : []}
                        setStore={setSelectedStores}
                        storeSelected={selectedStores}
                        multipleSelect={true}
                    />
                </Card>
            </GridColumn>
        </Grid>
    </div>
}

export const DataTableCustom = (props: any) => {
    const [rowsPerPage] = React.useState(10);
    const length = props.data.length;

    const handleChangePage = (
        // @ts-ignore
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        props.setPageCount(newPage);
    };

    const checkedCriteria = (ele: any) => {
        return !!props.selectedData.find((item: any) => item.storeNbr === ele.storeNbr)
    }
    return (
        <div className='ngo-table-wrap'>
            <div className='ngo-check-box ml-3 mt-2'>
                <LDCheckbox label='Select all'
                    checked={props.selectedData?.length === props.data?.length}
                    onChange={props.handleAllSelection}
                />
            </div>
            <Divider className='mb-2' />
            {props.data.length !== 0 ? props.data.slice(props.pageCount * rowsPerPage, props.pageCount * rowsPerPage + rowsPerPage)
                .map(((ele: any, index: number) => {
                    const showDivider = (index + 1) % 10 !== 0;
                    return <div key={`key-${ele.storeNbr}`}>
                        <div className='ngo-table-body'>
                            <div className='ngo-check-box'>
                                <LDCheckbox label=' '
                                    checked={checkedCriteria(ele)}
                                    checkboxProps={{ 'data-testid': 'checkbox-btn' }}
                                    onChange={(event: any) => props.handleSelection(event, ele)} />
                            </div>
                            <div className='ngo-table-content'>
                                <div>
                                    {`${ele.storeName} (#${ele.storeNbr})`}
                                </div>
                                <div>
                                    {`${ele.addressLine1}, ${ele.city}, ${ele.state}, ${ele.postalCode}`}
                                </div>
                            </div>
                        </div>
                        {showDivider && <Divider />}
                    </div>
                })) : <div className='ngo-table-content'>No data available</div>}
            <div className='pagination-block'>
                <Divider />
                <TablePagination
                    component='div'
                    count={length}
                    page={props.pageCount}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[10]}
                />
            </div>
        </div>
    )
}

export default NgoNcSelectStoreComp;
