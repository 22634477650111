import { Card } from '@walmart-web/livingdesign-components';
import { ChevronDown, ChevronUp } from '@livingdesign/icons';
import moment from 'moment';
import React, { useContext, useState } from 'react';
import { useQuery, useMutation } from 'react-query';
import SpaceDonationDataService, { Service } from 'src/spaceDonationService';
import Loader from '../../../commonComponents/appComponents/loader/loader';
import LDDivider from '../../../commonComponents/uiComponents/LDDivider';
import LDButton from '../../../commonComponents/uiComponents/LDButton';
import LDModal from '../../../commonComponents/uiComponents/LDModal';
import LDSelect from '../../../commonComponents/uiComponents/LDSelect';
import './style.css';
import UserContext from 'src/components/spacedonation/store/user-context';


export const DisplayEventInformation = (props: any) => {
    const [openContactEdit, setOpenContactEdit] = React.useState(false);
    const [index, setIndex] = React.useState(0);
    const [email, setEmail] = useState();
    const [phoneNo, setPhoneNo] = useState();
    const [currentName, setCurrentName] = useState('');
    let contactDetails: any
    const contactDetailsList = props?.allContacts ? props?.allContacts : [];
    contactDetails = contactDetailsList?.filter?.((item: any) => item?.nationalCampaignContact);
    contactDetails = contactDetails ? contactDetails : [];
    const [modalEmail, setModalEmail] = useState();
    const [modalPhoneNo, setModalPhoneNo] = useState();
    const [modalName, setModalName] = useState('');
    const [modalIndex, setModalIndex] = useState(0);
    const mutation = useMutation(service.updateEventContact);
    const userContext: any = useContext(UserContext);
    const orgName = userContext?.organization?.orgName;

    React.useEffect(() => {
        setEmail(contactDetails && contactDetails.length > 0 ? contactDetails[0]?.eventContactDetails?.spaceOrganizationEmail : '')
        setPhoneNo(contactDetails && contactDetails.length > 0 ? contactDetails[0]?.eventContactDetails?.spaceOrganizationPhone : '')
        setCurrentName(contactDetails && contactDetails.length > 0
            ? contactDetails[0]?.eventContactDetails?.contactFirstName + ' ' + contactDetails[0]?.eventContactDetails?.contactLastName : '');
    }, [props.loading])

    const handleContactChange = (e: any) => {
        setModalIndex(e.target.value);
        setModalEmail(contactDetailsList && contactDetailsList.length > 0 ? contactDetailsList[e.target.value]?.eventContactDetails?.spaceOrganizationEmail : '');
        setModalPhoneNo(contactDetailsList && contactDetailsList.length > 0 ? contactDetailsList[e.target.value]?.eventContactDetails?.spaceOrganizationPhone : '')
        setModalName(contactDetailsList && contactDetailsList.length > 0
            ? contactDetailsList[e.target.value]?.eventContactDetails?.contactFirstName + ' ' + contactDetailsList[e.target.value]?.eventContactDetails?.contactLastName : '')
    }
    /* istanbul ignore next */
    const handleContactUpdate = () => {
        setOpenContactEdit(false);
        const data = {
            orgId: props?.orgId,
            ncId: props?.ncId,
            payload: {
                contactFirstName: contactDetailsList[modalIndex]?.eventContactDetails?.contactFirstName,
                contactLastName: contactDetailsList[modalIndex]?.eventContactDetails?.contactLastName,
                spaceOrganizationName: orgName,
                spaceOrganizationEmail: modalEmail,
                spaceOrganizationPhone: modalPhoneNo,
            }
        }
        mutation.mutate(data, {
            onSuccess: () => {
                setIndex(modalIndex)
                setEmail(modalEmail)
                setPhoneNo(modalPhoneNo)
                setCurrentName(modalName)
                // props.refetch();
            },
        });
    }

    const openModal = () => {
        setModalIndex(index)
        setModalEmail(email)
        setModalPhoneNo(phoneNo)
        setModalName(currentName)
        setOpenContactEdit(true);
    }

    const getTimeSlots = () => {
        return <div>{props?.data?.eventSlots?.map((val: any) => <div className='time-slots-display' key={val.slotName}>{val.slotName + ' (' + val.label + ')'}</div>)}</div>;
    }
    return (
        // @ts-ignore
        <Card size='medium' className='nc-event-detail-information-card'>
            {mutation.isLoading && <Loader />}
            <div className='event-details-information-header-section'>
                <span className='event-details-information-header-title'>
                    {props?.type === 'info' ? `Event ` : `Contact `} Information
                </span>
                {/* istanbul ignore next */
                    props?.state ? <ChevronUp
                        className='chevron'
                        children='Show/Hide Content'
                        aria-expanded={props?.state}
                        onClick={props?.handleStateContactChange}
                    /> : <ChevronDown
                        className='chevron'
                        children='Show/Hide Content'
                        aria-expanded={props?.state}
                        onClick={props?.handleStateContactChange}
                    />}
            </div>
            <LDDivider className='event-details-divider' />
            {props?.state && props?.type === 'info' ?
                <><div className='event-details-display-text'>
                    <div className='event-detail-segments'>
                        <div className='detail-segment detail-segment-left'>
                            <div className='event-details-display-text'>
                                <span className='event-details-display-text-label'>Type of event:</span>
                                <span>National Campaign</span>
                            </div>
                            <div className='event-details-display-text'>
                                <span className='event-details-display-text-label'>Start date:</span>
                                <span>{moment(props?.data?.eventStartDateString).format('ddd MMM DD, yyyy')}</span>
                            </div>
                            <div className='event-details-display-text'>
                                <span className='event-details-display-text-label'>Campaign Period:</span>
                                <span>{moment(props?.data?.eventStartDateString).format('ddd MMM DD, yyyy')} -
                                    {' '} {moment(props?.data?.eventEndDateString).format('ddd MMM DD, yyyy')}</span>
                            </div>
                            <div className='event-details-display-text border-bottom-0 mb-2'>
                                <span className='event-details-display-text-label'>Time:</span>
                                <span className='event-details-display-value'>{getTimeSlots()}</span>
                            </div>
                        </div>
                        <div className='detail-segment'>
                            <div className='event-details-display-text'>
                                <span className='event-details-display-text-label'>Purpose:</span>
                                <span className='event-details-display-value'>{props?.data?.purpose}</span>
                            </div>
                            <div className='event-details-display-text'>
                                <span className='event-details-display-text-label'>Focus area:</span>
                                <span className='event-details-display-value'>{props?.data?.focusArea}</span>
                            </div>
                            <div className='event-details-display-text'>
                                <span className='event-details-display-text-label'>Event Space:</span>
                                <span className='event-details-display-value'>{props?.data?.location} | {props?.data?.locationName}</span>
                            </div>
                        </div>
                    </div>
                </div>
                    <div className='event-ngo-details-display-description-wrap mb-4'>
                        <div className='event-ngo-details-display-description'>Description:</div>
                        <div className='event-ngo-details-display-description-value mr-2'>{props?.data?.description}</div>
                    </div>
                </> :
                props?.state &&
                <>
                    <LDModal
                        isOpen={openContactEdit}
                        onClose={() => setOpenContactEdit(false)}
                        size='small'
                        title='Edit Contact'
                    >
                        <div className='ngo-nc-edit-contact-modal'>
                            <div className='ngo-nc-edit-contact-details'>
                                <span className='ngo-nc-edit-contact-label'>Full Name</span>
                                <LDSelect
                                    label={undefined}
                                    onChange={handleContactChange}
                                >
                                    <option value={index}>{modalName}</option>
                                    {contactDetailsList?.map?.(
                                        (item: any, contactIndex: any) => Number(modalIndex) !== contactIndex && <option
                                            value={contactIndex}
                                            key={contactIndex}>{item?.eventContactDetails?.contactFirstName} {item?.eventContactDetails?.contactLastName}
                                        </option>)}
                                </LDSelect>
                            </div>
                            <div className='ngo-nc-edit-contact-details'>
                                <span className='ngo-nc-edit-contact-label'>Email address</span>
                                <span>{modalEmail}</span>
                            </div>
                            <div className='ngo-nc-edit-contact-details'>
                                <span className='ngo-nc-edit-contact-label'>Phone number</span>
                                <span>{modalPhoneNo}</span>
                            </div>
                            <div className='ngo-nc-edit-contact-details'>
                                <span className='ngo-nc-edit-contact-label'>Organization</span>
                                <span>{orgName}</span>
                            </div>
                        </div>
                        <LDDivider />
                        <div className='ngo-nc-modal-edit-contact--buttons'>
                            <div className='ngo-nc-cancel-edit-contact-button'>
                                <LDButton data-testid='close-modal' onClick={() => setOpenContactEdit(false)}
                                    variant='tertiary'>
                                    Cancel
                                </LDButton>
                            </div>
                            <div><LDButton variant='primary' data-testid='update-contact'
                                onClick={handleContactUpdate}>Update</LDButton></div>
                        </div>
                    </LDModal>
                    <div className='event-details-display-text'>
                        <div className='event-detail-segments'>
                            <div className='detail-segment detail-segment-left'>
                                <div className='event-details-display-text'>
                                    <span className='event-details-display-text-label'>Full names:</span>
                                    <span>{currentName}</span>
                                </div>
                                <div className='event-details-display-text border-none'>
                                    <span className='event-details-display-text-label'>Phone number:</span>
                                    <span>{phoneNo}</span>
                                </div>

                            </div>
                            <div className='detail-segment'>
                                <div className='event-details-display-text'>
                                    <span className='event-details-display-text-label'>Email address:</span>
                                    <span className='event-details-display-value'>{email}</span>
                                </div>
                                <div className='event-details-display-text border-none'>
                                    <span className='event-details-display-text-label'>Organization:</span>
                                    <span className='event-details-display-value'>{orgName}</span>
                                </div>
                            </div>
                        </div></div>
                    <div className='ngo-nc-edit-btn'>
                        <LDButton data-testid='edit-contact' variant='secondary' onClick={() => openModal()}>Edit contact</LDButton>
                    </div>
                </>
            }
        </Card>
    )
}

export interface EventInfoProp {
    ncId?: any,
    orgId?: any
}
const service: Service = SpaceDonationDataService;
export const EventInfo: React.FunctionComponent<EventInfoProp> = (props) => {
    const [state, setState] = useState(true);
    const [contact, setContact] = useState(false);
    const { isLoading, data } = useQuery(['getEventInfo', { id: props?.ncId }],
        service.getEventInfo, { refetchOnWindowFocus: false })
    /* istanbul ignore next */
    const handleStateChange = () => {
        setState((preState) => !preState);
    }
    const handleStateContactChange = () => {
        setContact((preState) => !preState);
    }
    const contactMutation = useMutation((data: any) =>
        service.getAllContactDetails(data)
    );
    const contactPayload: any = {
        orgId: props?.orgId,
        ncId: props?.ncId,
    };
    React.useEffect(() => {
        contactMutation.mutate(contactPayload);
    }, contactPayload);
    return (<div>
        {(isLoading || contactMutation?.isLoading) && <Loader />}
        <DisplayEventInformation name='Event' handleStateContactChange={handleStateChange}
            state={state} type='info' data={data} />
        <DisplayEventInformation
            name='Event'
            handleStateContactChange={handleStateContactChange}
            state={contact}
            type='contact'
            allContacts={contactMutation?.data}
            loading={contactMutation?.isLoading}
            ncId={props?.ncId}
            orgId={props?.orgId}
        />
    </div>)
}
