import * as React from 'react';
import { RouteComponentProps } from 'src/polyfils/customRouter';
import withRouter from 'src/polyfils/customRouter';
import { Box, IconButton, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { GlobalDonationState } from '../../../models/globalDonationState';
import { ArrowBack } from '@material-ui/icons';
import {IMonetarySocialImpactReport, defaultMonetarySocialImpactReport, ISocialImpactResponse, transformSocialImpactData} from './socialImpactReportData';
import { saveFormData } from 'dynamic-form-components/lib/sagas';
import { viewOrDownloadFile } from 'src/state/ManageWorkflow/fileActions';
import MonetarySocialImpactReportTab from './MonetarySocialImpactReportTab';
import GlobalDonationDataService from 'src/service';
import { DashBoardTypes } from 'src/constants';
import { BreadcrumbsComponent } from 'src/components/common';
import { LoadingSkeleton } from 'src/components/donations/moreDetailsSections/common/LoadingSkeleton';
import { withTranslation } from 'react-i18next';

import analytics from '../../../adobe-analytics/analytics';
import { ANALYTICS } from '../../../adobe-analytics/analytics-dataelemets';

export interface OwnProps {
    classes?: any;
    t: (key: string) => string,
    loggedInUserDetail: any;
}


interface IProjectData {
  projectId: number,
  projectName: string,
  amountGranted: number,
  amountReported: number,
  activitiesInProgress: number,
  activitiesCompleted: number,
  status: string,
  lastUpdateDate: Date,
}
export interface DispatchProps {
    submitReport: (data: any) => void;
    downloadFile: (data: any) => void;
}

export interface StateProps {
    selectedLang: string;
    projectData: IProjectData;
}

const dashboardUrl = `/GlobalDonations/gdms/dashboard/social-impact-monetary?type=social-impact-monetary`;
const breadcrumbsData: any = [
    {
        name: 'monetaryDonation.nav.home',
        path: '/',
    },
    {
        name: 'socialImpactReport.name',
        path: dashboardUrl
    },
]

const styles = {
    backIcon: {
        marginLeft:'-12px'
    }
}

let apiResponse: ISocialImpactResponse | null = null;

class MonetarySocialImpactReport extends React.PureComponent<OwnProps & RouteComponentProps & StateProps & DispatchProps> {
    public state: {reportData: IMonetarySocialImpactReport, loading: boolean} = {
        reportData: defaultMonetarySocialImpactReport,
        loading: true,
    }

    private readonly onPageBack = () => {
        this.props.history.push(dashboardUrl);
    }

    public async componentDidMount() {
    analytics.trackPageInfoGdmsExternal(ANALYTICS.socialImpact.goodsRecurringReportPage);
        if(!this.props.projectData) {
          this.onPageBack();
        } else {

            apiResponse = await GlobalDonationDataService.getMonetarySocialImpactReport(this.props.projectData.projectId);
            if(apiResponse) {
                this.setState({...this.state, loading: false,reportData: transformSocialImpactData(apiResponse)});
            } else {
              this.setState({...this.state, loading: false});
            }

        }
    }

    public render() {
        return <>
           <Box>
                <BreadcrumbsComponent
                    breadcrumbsData={breadcrumbsData}
                    history={this.props.history} />
                <Typography style = {{fontWeight: 'bold'}} variant='h5'>{this.props.t('socialImpactReport.title')}</Typography>
                <Box display='flex' alignItems = 'center' mt = {1} mb = {2}>
                    <IconButton
                        className = {this.props.classes.backIcon}
                        onClick = {this.onPageBack}>
                        <ArrowBack />
                    </IconButton>
                    <Typography style = {{fontWeight: 'bold'}} variant='h5'>
                        {`${this.props.projectData?.projectId} - ${this.props.projectData?.projectName}`}
                    </Typography>
                </Box>
                {
                  !this.state.loading ? <MonetarySocialImpactReportTab
                  data-testid = 'monetarySocialImpactReportTab'
                  selectedLang ={this.props.selectedLang}
                  reportData = {this.state.reportData}
                  reportId = {this.props.projectData?.projectId}
                  onPageBack = {this.onPageBack}
                  responseData = {apiResponse}
                  isFormEditable = {true}/> :
                  <LoadingSkeleton data-testid = 'loading' />
                }
           </Box>
        </>
    }
}

const mapStateToProps = (state: GlobalDonationState): StateProps => ({
    selectedLang: state.selectedLang,
    projectData: state.selectedDataState?.addDataToStore?
      state.selectedDataState?.addDataToStore[DashBoardTypes.SocialImpactMonetaryDashBoard]: null
});

export const mapDispatchToProps = (dispatch: React.Dispatch<any>): DispatchProps => ({
    submitReport: (data: any) => dispatch(saveFormData(data)),
    downloadFile: (data) => dispatch(viewOrDownloadFile(data))
});

export default withStyles(styles)(withTranslation()(
    withRouter(connect(mapStateToProps, mapDispatchToProps)(MonetarySocialImpactReport))));
