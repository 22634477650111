import React, { useContext, useEffect, useState } from "react";
import "./requestSpace.css";
import LDCard from "../../commonComponents/uiComponents/LDCard";
import LDButton from "../../commonComponents/uiComponents/LDButton";
import UserContext from "../../store/user-context";
import { Radio, RadioGroup, FormControlLabel } from "@material-ui/core";
import moment from "moment";
import { useNavigate } from "react-router";
import SpaceDonationService, { Service } from "src/spaceDonationService";
import { useQuery } from "react-query";
import FlagLogo from "../../../../../src/resources/flag.svg";
import StoreLogo from "../../../../../src/resources/store-location.svg";
import CalenderLogo from "../../../../../src/resources/calendar.svg";
import { Tag } from "@walmart-web/livingdesign-components";

import Loader from "../../commonComponents/appComponents/loader/loader";
const service: Service = SpaceDonationService;
export interface RequestSpaceProps {
  hist?: any[];
}
const RequestSpace: React.FunctionComponent<RequestSpaceProps> = () => {
  const userContext: any = useContext(UserContext);
  console.log(userContext);
  const isVerified = userContext?.organization?.isVerified;
  const { status, data, isFetching } = useQuery(
    ["getMyOrgData"],
    service.getMyOrgData,
    {
      refetchOnWindowFocus: false,
    }
  );
  const [dataFetched, setDataFetched] = useState(false);

  useEffect(() => {
    if (!isFetching && "success" === status) {
      userContext.setOrg(data);
      setDataFetched(true);
    }
    if (!isFetching && "error" === status) {
      userContext.logout();
    }
  }, [isFetching]);
  const history = useNavigate();
  const [ncid, setNcid] = useState("");
  const org = userContext.organization;
  const orgJson = JSON.parse(JSON.stringify(org));
  const orgId = `${orgJson.id}`;
  const { data: apiData } = useQuery(
    ["fetchNcEventDetails", { orgId }],
    service.fetchNcEventDetails,
    { refetchOnWindowFocus: false }
  );
  console.log(apiData);
  const handleRadio = (e: any) => {
    setNcid(e.target.value.toString());
  };
  const NameTag = (props: any) => {
    return (
      <span className="list-item-tag-name">
        {props.name} &nbsp;
        {props.isDraft && (
          <Tag color="blue" variant="tertiary">
            Draft
          </Tag>
        )}
      </span>
    );
  };
  const listItems = apiData?.map((apidata: any) => (
    <FormControlLabel
      className="form-control"
      key={apidata.id + ""}
      value={apidata.id + ""}
      control={<Radio color="default" />}
      label={
        <NameTag name={apidata.title} isDraft={apidata.isEventStatusDraft} />
      }
    />
  ));
  const foundarr = apiData?.find((obj: any) => {
    return obj.id === Number(ncid);
  });
  const handleRedirect = () => {
    if (orgId && ncid) {
      history(`/GlobalDonations/gdms/space-donation/create/store-select`, {
        state: { ncid, spaceOrganizationid: orgId },
      });
    }
  };
  const onClickLocal = () => {
    history(`/GlobalDonations/gdms/space-donation/create/new`, { state: "" });
  };
  return (
    <div className="request-space-root">
      {isFetching && <Loader />}
      <LDCard className="big-card-wrap" size="small">
        <div className="events-div">
          <h5>
            <b>Please select an event</b>
          </h5>
          {isVerified && (
            <div className="home-office-events">
              <h6>
                <img alt="Calendar logo" src={FlagLogo} />
                <b> Campaign events</b>
              </h6>
              <ul className="unordered-list">
                <RadioGroup
                  className="radio-group-1"
                  value={ncid + ""}
                  onChange={handleRadio}
                >
                  {listItems}
                </RadioGroup>
              </ul>
            </div>
          )}
          <div className="local-events">
            <h6>
              <img alt="Calendar logo" src={StoreLogo} />
              <b> Local event</b>
            </h6>
            <ul>
              <RadioGroup
                value="Local radio button"
                onChange={() => onClickLocal()}
              >
                <FormControlLabel
                  value="Create a local event"
                  control={
                    <Radio color="default" disabled={!org?.isVerified} />
                  }
                  label="Create a local event"
                />
              </RadioGroup>
            </ul>
          </div>
          {dataFetched &&
            !org?.isVerified &&
            ((org?.onboardingStatus &&
              (org?.onboardingStatus === "INACTIVE" ||
                org?.onboardingStatus === "INCOMPLETE")) ||
            userContext?.organization?.cybergrantsOrganizationId ? (
              <div className="request-space-label">
                Your organization has lost its eligibility to participate in
                Spark Good programs because you have not completed onboarding
                with Deed. If you have upcoming approved events, you can still
                access your events to manage at your local facility. Login to
                Deed to finish onboarding. Your ability to apply for space is
                disabled until onboarding is completed.
              </div>
            ) : (
              <div className="request-space-label">
                Your profile is still pending review to have access to the Space
                Request Tool. You will be notified once your profile has
                completed the manual review process.
              </div>
            ))}
        </div>
        <div className="events-sep" />
        <hr className="mobile-view-sep" />
        <div className="events-desc">
          <div className="mobile-btn-margin">
            <LDButton
              data-testid="req-walmart-btn"
              className="request-space-btn"
              disabled={!ncid}
              variant="primary"
              onClick={() => handleRedirect()}
            >
              Request Walmart space
            </LDButton>
            <hr className="mobile-view-sep" />
          </div>
          <div className="handle-mobile-view">
            {ncid === "" && (
              <div className="calendar-logo">
                <img alt="Calendar logo" src={CalenderLogo} />
              </div>
            )}
            {ncid === "" && (
              <div className="calendar-desc">
                Event information will show up here
              </div>
            )}
          </div>
          {ncid !== "" && <DisplayEventInformation details={foundarr} />}
        </div>
      </LDCard>
    </div>
  );
};
export interface DisplayEventDetailsInfoProp {
  details: any;
}
export const DisplayEventInformation: React.FunctionComponent<
  DisplayEventDetailsInfoProp
> = ({ details }) => {
  const format = "ddd MMM DD, YYYY";
  const startDate = moment(details?.eventStartDateString).format(format);
  const endDate = moment(details?.eventEndDateString).format(format);
  const createdDate = moment(details?.audit?.createdDtm).format(format);
  const getTimeSlots = () => {
    return (
      <div>
        {details?.eventSlots?.map((val: any) => (
          <div className="time-slots-display">
            {val.slotName + " (" + val.label + ")"}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="request-space-event-info-card">
      <div className="event-detail-information-card" data-testid="div-1">
        <div
          className="event-details-information-header-section"
          data-testid="div-2"
        >
          <span className="event-details-information-header-title">
            Event Information
          </span>
        </div>
        <div>
          <div className="event-details-display-text" data-testid="div-3">
            <span className="event-details-display-text-label">
              Type of event:
            </span>
            <span>National Campaign</span>
          </div>
          <div className="event-details-display-text" data-testid="div-4">
            <span className="event-details-display-text-label">
              Created date:
            </span>
            <span>{createdDate}</span>
          </div>
          <div className="event-details-display-text" data-testid="div-5">
            <span className="event-details-display-text-label">
              Campaign proposal:
            </span>
            <span>
              {startDate} - {endDate}
            </span>
          </div>
          <div className="event-details-display-text" data-testid="div-6">
            <span className="event-details-display-text-label">Time:</span>
            <span className="event-details-display-value">
              {getTimeSlots()}
            </span>
          </div>
          <div className="event-details-display-text" data-testid="div-7">
            <span className="event-details-display-text-label">Purpose:</span>
            <span className="event-details-display-value">
              {details?.purpose}
            </span>
          </div>
          <div className="event-details-display-text" data-testid="div-8">
            <span className="event-details-display-text-label">
              Focus area:
            </span>
            <span className="event-details-display-value">
              {details?.focusArea}
            </span>
          </div>
          <div className="event-details-display-text" data-testid="div-9">
            <span className="event-details-display-text-label">
              Event Space:
            </span>
            <span className="event-details-display-value">
              {details?.spaceLocationDetails?.location} |{" "}
              {details?.spaceLocationDetails?.name}
            </span>
          </div>
          <div
            className="event-details-display-description"
            data-testid="div-10"
            style={{ display: "inherit" }}
          >
            <span className="event-details-display-text-label">
              Description:{" "}
            </span>
            <span className="event-details-display-description-value">
              {details?.description}{" "}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestSpace;
