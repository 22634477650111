import { useContext } from 'react';
import LDButton from '../../commonComponents/uiComponents/LDButton';
import './confirmationScreen.css';
import ADCard from '../../commonComponents/appComponents/advertisementCard/advertisementCard';
import SpaceDonationService, { Service } from '../../../../spaceDonationService';
import LoaderComponent from '../../commonComponents/appComponents/loader/LoaderComponent';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router';
import ConfirmLogo from 'src/resources/partyPopper.svg';
import UserContext from '../../store/user-context';

const service: Service = SpaceDonationService;

const ConfirmationScreen = () => {
    const history = useNavigate()
    const orgContext: any  = useContext(UserContext);
    console.log(orgContext);
    const orgVerified = orgContext?.organization?.isVerified;
    console.log('isverified-----',orgVerified);
    let org:string;
    if(orgVerified == true){
        org = 'verified';
    }
    else{
        org = 'nonVerified';
    }
    const handleEventViewRedirect = () =>{
        history(`/GlobalDonations/gdms/space-donation/events`);
    }
    const { data, isFetching } = useQuery(['fetchFocusAreas', { key: 'spaceAds', countryCode: 'US' }],
        service.getSpaceDonationConfigurations, { refetchOnWindowFocus: false });
    return <>{
        <div>
            <div className='confirmation-card'>
                <div className='confirmation-logo'>
                    <img alt='celebrationWithHands-logo'
                        src={ConfirmLogo} />
                </div>
                <h4 className='confirmation-header'>Thank you for your request!</h4>
                <div className='confirmation-text'>You will receive a notification when your request has been reviewed.</div>
                <LDButton variant='primary' size='medium'
                    className='confirmation-screen-button'
                    onClick={handleEventViewRedirect}>
                    Done
                </LDButton>
            </div>
            <br />
            {isFetching ? <LoaderComponent /> : <div>
                <div className='confirmation-card1'>
                    {data?.spaceAdsList?.length > 0 ?
                        <>
                        <div className='confirmation-header-card-2'>
                        Discover more opportunities for your organization</div>
                            <div className='row'>
                                {data?.spaceAdsList?.filter((ad: any) => ad?.adFile?.fileId &&
                                    (ad?.orgType === org)).map((ad: any, i: number) => {
                                        return <div className='col-xl-4 col-lg-6 col-md-12 col-sm-12 col-xs-12 ads-card' key={i}>
                                            <ADCard
                                                fileId={ad.adFile.fileId}
                                                directory={ad.adFile.container}
                                                header={ad.headLine}
                                                buttonName={ad.textToDisplay}
                                                buttonLink={ad.websiteUrl}>
                                                {ad.body}</ADCard>
                                        </div>
                                    })}
                            </div></> : <></>}
                </div>
                <br />
            </div>}
        </div>
    }</>
}

export default ConfirmationScreen;
